<template>
  <div :class="customClass" :id="elementId">
    <!--
    ////////////////////////////////////////////////////////////////////////////
    ///////////////// 轮播图 ////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    -->
    <div margin-auto style="position: relative;min-width: 1200px; background-color: #F1F1F1;">
      <!--      <tc-carousel arrow="hover" :height="computedWrapperStyle('carousel').height" :autoplay="true" :interval="5000">-->
      <tc-carousel arrow="hover" :height="computedWrapperStyle('carousel').height" :autoplay="true" :interval="5000">

        <template v-if="carouselList && carouselList.length > 0">
          <el-carousel-item v-for="(item,index) in carouselList" :key="index" @click.native="carouselImageClicked(item)">
            <img :src="normalizeImageUrl(item.surfacePlotUrl)" alt="" style="display:block;width: 100%;">
          </el-carousel-item>
        </template>

        <template v-else>
          <tc-carousel-item>
            <el-skeleton style="width: 100%;height: 100%;">
              <template slot="template">
                <el-skeleton-item mb10 variant="image" style="width: 100%; height: 100%;margin-bottom: 0;" />
              </template>
            </el-skeleton>
          </tc-carousel-item>
          <tc-carousel-item>
            <el-skeleton style="width: 100%;height: 100%;">
              <template slot="template">
                <el-skeleton-item mb10 variant="image" style="width: 100%; height: 100%;margin-bottom: 0;" />
              </template>
            </el-skeleton>
          </tc-carousel-item>
          <tc-carousel-item>
            <el-skeleton style="width: 100%;height: 100%;">
              <template slot="template">
                <el-skeleton-item mb10 variant="image" style="width: 100%; height: 100%;margin-bottom: 0;" />
              </template>
            </el-skeleton>
          </tc-carousel-item>
        </template>

      </tc-carousel>
    </div>

    <!--
    ////////////////////////////////////////////////////////////////////////////
    ///////////////// 立即报名 & 开赛时间 ////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    -->
    <div style="position: relative;" :style="{height:registrationNowAndStartTimeComponentWrapperHeight}">
      <registration-now-and-start-time-component ref="registrationNowAndStartTimeComponent" style="z-index:999;position: absolute; left: 50%; transform  : translate(-50%, 0);" :style="{top:registrationNowAndStartTimeComponentTop}"></registration-now-and-start-time-component>
    </div>
    <search-component></search-component>
    <news-and-notice-component></news-and-notice-component>
    <video-list-component></video-list-component>
    <wonderful-pictures-component></wonderful-pictures-component>
    <sponsor-list-component></sponsor-list-component>
    <div style="height: 60px;"></div>
    <!--
    ////////////////////////////////////////////////////////////////////////////
    ///////////////// Dialog ///////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    -->
    <!-- <signup-or-search-dialog :visible.sync="signupOrSearch"></signup-or-search-dialog>-->
  </div>
</template>

<script type="text/ecmascript-6">
import TcCarousel     from '@/components/tc-carousel/main'
import TcCarouselItem from '@/components/tc-carousel/item'
import Global         from '@/mixins/global'
import moment         from 'moment'
import 'moment/locale/zh-cn'

moment.locale('zh-cn')

import VideoListComponent                   from './components/video-list-component'
import SponsorListComponent                 from './components/sponsor-list-component'
import WonderfulPicturesComponent           from './components/wonderful-pictures-component'
import NewsAndNoticeComponent               from './components/news-and-notice-component'
import SearchComponent                      from './components/search-component'
import RegistrationNowAndStartTimeComponent from './components/registration-now-and-start-time-component'
import DataConfigMixin                      from '@/mixins/data-config-mixin'

export default {
  provide() {
    return {
      home: this,
    }
  },
  mixins    : [Global, DataConfigMixin],
  components: {
    RegistrationNowAndStartTimeComponent,
    VideoListComponent,
    SponsorListComponent,
    WonderfulPicturesComponent,
    NewsAndNoticeComponent,
    SearchComponent,
    ///
    TcCarousel,
    TcCarouselItem,
    // SignupOrSearchDialog,
  },
  name      : 'Home',
  props     : {
    className: { type: String, default: '' },
    // formObject: { type: Object, default: () => {} },
  },
  data() {
    return {
      currentFormObject                                : null,
      registrationNowAndStartTimeComponentTop          : 0,
      registrationNowAndStartTimeComponentWrapperHeight: '145px',
    }
  },
  watch   : {},
  computed: {
    computedWrapperStyle() {
      return (field) => {
        let clientWidth = this.$store.state.clientWidth
        if (clientWidth < 1200) {clientWidth = 1200}
        if (field == 'logo') {return { height: clientWidth * 100 / 1200 + 'px' }}
        if (field == 'copyright') {return { height: clientWidth * 217 / 1200 + 'px' }}
        // if (field == 'carousel') {return { height: clientWidth * 450 / 1200 + 'px' }}
        // if (field == 'carousel') {return { height: clientWidth * 720 / 1440 + 'px' }}
        if (field == 'carousel') {

          let registrationNowAndStartTimeComponent = this.$refs.registrationNowAndStartTimeComponent
          if (registrationNowAndStartTimeComponent) {
            let computedStyle = window.getComputedStyle(registrationNowAndStartTimeComponent.$el)
            console.log('【home:130】', `computedStyle = `, computedStyle)

            let height                                   = computedStyle.height
            this.registrationNowAndStartTimeComponentTop = -1 * Number(height.replace('px', '')) * 2 / 3 + 'px'
          }
          else {
            this.registrationNowAndStartTimeComponentTop = -1 * clientWidth * 90 / 1440 + 'px'
          }

          this.registrationNowAndStartTimeComponentWrapperHeight = `calc(145px + ${this.registrationNowAndStartTimeComponentTop} + 45px)`

          let value = `calc(${this.registrationNowAndStartTimeComponentTop} - 10px)`
          console.log('【home:140】', `value = `, value)
          document.documentElement.style.setProperty('--custom-el-carousel__indicator-top', value)
          return { height: clientWidth * 720 / 1440 + 'px' }
        }
        return {}
      }
    },
    computedCreateTime() {
      return (createTime) => {
        // return moment(createTime).format('YYYY年MM月DD日')
        return moment(createTime).format('YYYY.MM.DD')
      }
    },
    computedCustomCarouselItem() {
      if (this.$store.state.raceAnnouncementList && this.$store.state.raceAnnouncementList.length > 0) {
        if (this.$store.state.raceAnnouncementList.length == 1 || this.$store.state.raceAnnouncementList.length == 2) {
          return 'custom-carousel-item-half'
        }
        else {
          return ''
        }
      }
      else {
        return ''
      }

    },
    computedRaceAnnouncementCarouselHeight() {
      if (this.$store.state.raceAnnouncementList && this.$store.state.raceAnnouncementList.length > 0) {
        if (this.$store.state.raceAnnouncementList.length == 1 || this.$store.state.raceAnnouncementList.length == 2) {
          return ((524 - 88) * 0.5) + 'px'
        }
        else {
          return (524 - 88) + 'px'
        }
      }
      else {
        return (524 - 88) + 'px'
      }
    },
    elementId() {return `Home_${this._uid}`},
    customClass() {return ['Home', this.className]},
    ///
    // ...mapState({
    //   carouselList: state => state.carouselList,
    // }),

    carouselList() {
      return this.dataConfig.carouselList
    },
  },
  methods : {
    normalizeOpenURL(url) {
      if (url) {
        // 有
        if (url.startsWith('http') || url.startsWith('https') || url.startsWith('blob')) {
          return url
        }
        else {
          return '//' + url
        }
      }
      else {
        // 没有
        return url
      }
    },
    carouselImageClicked(item) {
      console.log('item = ', item)
      // jumpType      : 0, 	 // integer($int32) 跳转类型 0：无跳转 1：内容-文章 2：外部链接
      // switch (item.jumpType) {
      //   case 0:
      //     break
      //   case 1:
      //     roomsApi.officialWebsiteContentDetail({ contentId: item.jumpUrl }).then((res) => {
      //       this.handleResponse(res, (data) => {
      //         console.log('data = ', data)
      //         this.currentFormObject = data
      //       })
      //     }).catch((err) => {
      //       console.log(`\x1b[41m${err}\x1b[0m`)
      //     })
      //     break
      //   case 2:
      //     // window.open(item.jumpUrl)
      //     window.open(this.normalizeOpenURL(item.jumpUrl))
      //     break
      //   default:
      //     console.log('not matched')
      // }
      if (item.jumpUrl) {
        window.open(this.normalizeOpenURL(item.jumpUrl))
      }
    },
  },

  created() {
    // document.documentElement.scrollTop = 0
  },
  destroyed() {
    // this.$root.$off('change-current-form-object')
  },
  mounted() {
    console.log('%c【index.vue:425】-:', 'color:orange', 'call home.vue\'s mounted')
    // this.$store.dispatch('aCarouselList')
    // this.$store.dispatch('aRaceAnnouncementList')
    // this.$store.dispatch('aNewsList')
  },
}
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "index.scss";

::v-deep .el-skeleton {
  height : 100%;
}

//

.Home {
  ::v-deep .el-carousel__indicator {
    position : relative;
    //left     : 0;
    top      : var(--custom-el-carousel__indicator-top);
  }
}

</style>

<style lang="scss" rel="stylesheet/scss">

:root {
  --custom-el-carousel__indicator-top : 0;
}

</style>

