<template>
  <div :class="customClass" :id="elementId">
    <div w1200 margin-auto flex-row-space-between-flex-start>
      <!-- 赛事新闻 -->
      <div class="race-news-container" style="position: relative;">
        <!--        <img src="../../resources/title-icon-news@2x.png" style="display:block;width: 40px;height: 40px;" alt="">-->
        <div class="race-news-head">
          <div flex-row-flex-start-center>
            <img src="@/assets/img/home/line.png" style="display:block;height:39px" alt="">
            <span style="width: 17px;"></span>
            <div flex-row-flex-start-flex-start>
              <p fz34 font-weight-600>新闻资讯</p>
            </div>
          </div>
          <img src="@/assets/img/home/more-right.png" @click="moreRaceNewsClicked" style="height:28px;cursor:pointer;" alt="">
        </div>
        <div class="list race-news-list">
          <div class="item-container" v-for="(item,index) in recommendRaceNews" :key="index" @click="recommendRaceNewsItemClicked(item)">
            <div class="item-left">
              <p class="date">{{ computedDateTime(item.time) }}</p>
              <p class="year">{{ computedYear(item.time) }}</p>
              <span></span>
            </div>
            <div class="item-right">
              <p class="title">{{ item.title }}</p>
              <p class="summary">{{ item.summary }}</p>
            </div>
          </div>
        </div>
        <!--        <el-button @click="moreRaceNewsClicked">更多</el-button>-->
      </div>
      <!-- 活动公告 -->
      <div class="active-notice-container" style="position: relative;">
        <!--        <img src="../../resources/title-icon-news@2x.png" style="display:block;width: 40px;height: 40px;" alt="">-->
        <div class="active-notice-head">
          <div flex-row-flex-start-center>
            <img src="@/assets/img/home/line.png" style="display:block;height:39px" alt="">
            <span style="width: 20px;"></span>
            <div flex-row-flex-start-flex-start>
              <p fz34 font-weight-600>赛事公告</p>
            </div>
          </div>
          <img src="@/assets/img/home/more-right.png" @click="moreActivityBulletinClicked" style="height:28px;cursor:pointer;" alt="">
        </div>
        <div class="list active-notice-list">
          <div class="item-container" v-for="(item,index) in recommendActivityBulletin" :key="index" @click="recommendActivityBulletinItemClicked(item)">
            <div class="item-left">
              <p class="date">{{ computedDateTime(item.time) }}</p>
              <p class="year">{{ computedYear(item.time) }}</p>
              <span></span>
            </div>
            <div class="item-right">
              <p class="title">{{ item.title }}</p>
              <p class="summary">{{ item.summary }}</p>
            </div>
          </div>
        </div>
        <!--        <el-button @click="moreActivityBulletinClicked">更多</el-button>-->
      </div>
    </div>
    <!--    <div w1200 margin-auto>-->
    <!--      -->
    <!--    </div>-->
  </div>
</template>

<script type="text/ecmascript-6">
import moment               from 'moment'
import 'moment/locale/zh-cn'
import DataConfigMixin      from '@/mixins/data-config-mixin'
import {cloneDeep, isEmpty} from 'lodash'

moment.locale('zh-cn')
export default {
  inject: {
    home: { from: 'home' },
  },
  // extends   : {},
  mixins    : [DataConfigMixin],
  components: {},
  name      : 'NewsAndNoticeComponent',
  props     : {
    className: { type: String, default: '' },
  },
  data() {
    return {}
  },
  watch   : {
    // someObject: { handler: function (val, oldVal) { /* ... */ }, deep: true, immediate: true },
  },
  computed: {
    computedYear() {
      return (time) => {
        if (Object.prototype.toString.call(time) === '[object String]') {
          return moment(time, 'YYYY.MM.DD').format('YYYY')
        }
        else if (Object.prototype.toString.call(time) === '[object Number]') {
          return moment(time).format('YYYY')
        }
        else {
          return moment(time).format('YYYY')
        }
      }
    },
    computedDateTime() {
      return (time) => {
        if (Object.prototype.toString.call(time) === '[object String]') {
          // return moment(time, 'YYYY.MM.DD').format('MM·DD')
          return time
        }
        else if (Object.prototype.toString.call(time) === '[object Number]') {
          return moment(time).format('MM·DD')
        }
        else {
          return moment(time).format('MM·DD')
        }
      }
    },
    recommendRaceNews() {
      let list = this.dataConfig.newsNotice.raceNews.filter(item => {
        return item.recommend
      })

      if (isEmpty(list)) {return []}

      if (list.length > 3) {
        return [list[0], list[1], list[2]]
        // return []
      }

      return list
    },
    recommendActivityBulletin() {
      let list = this.dataConfig.newsNotice.activityBulletin.filter(item => {
        return item.recommend
      })

      if (isEmpty(list)) {return []}

      if (list.length > 3) {
        return [list[0], list[1], list[2]]
        // return []
      }

      return list
    },
    elementId() {return `NewsAndNoticeComponent_${this._uid}`},
    customClass() {return ['NewsAndNoticeComponent', this.className]},
  },
  methods : {
    recommendActivityBulletinItemClicked(item) {
      this.$router.push({ name: 'activityBulletin', query: { d: item.id } })
    },
    recommendRaceNewsItemClicked(item) {
      this.$router.push({ name: 'raceNews', query: { d: item.id } })
    },
    moreRaceNewsClicked() {
      this.$router.push({ name: 'raceNews' })
    },
    moreActivityBulletinClicked() {
      this.$router.push({ name: 'activityBulletin' })
    },
  },

  created() {
    // document.documentElement.scrollTop = 0
  },
  destroyed() {},
  mounted() {},
}
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "index.scss";
</style>
